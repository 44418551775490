import React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../components/Layout';
// import SubscribeSide from '../components/Subscribe/SideOptIn2';
import Top5Recipes from '../components/Top5Recipes';
// import Top5Posts from '../components/Top5Posts';
import Button from '@material-ui/core/Button';
import ShareWidget from '../components/ShareWidget';
import LinkIcon from '@material-ui/icons/Link';
import PostOptin from '../components/Subscribe/PostOptin';
import TopBlogMob from '../components/Advertising/TopBlogMob';
import BlogTopDsktp from '../components/Advertising/BlogTopDsktp';
import BlogBttmDsktp from '../components/Advertising/BlogBttmDsktp';
import BlogBttmMob from '../components/Advertising/BlogBttmMob';
import SeoAdvice from '../components/SEO/SeoAdvice';

const Advice = ({ data, pageContext }) => {
  const { advicesData } = data;
  console.log('advicesData ---> ', advicesData);

  let sourceName = '';
  let sourceDescription = '';
  const referenceUrl = advicesData.data.reference.raw.url;
  sourceName = advicesData.data.source.document?.data.source_name.text;
  sourceDescription = advicesData.data.source.document?.data.description.text;
  return (
    <Layout>
              <SeoAdvice
          title={`${advicesData.data.title_advice.text}`}
          // date={PostData.data.release_date}
          description={`${advicesData.data.description_advice.text}`}
          article={true}
          // lastmodified={PostData.last_publication_date}
          //language={PostData.lang}
          //featuredImage={imageFeatured}
          //authorData={PostData.data.author.document}
         // authorImage={theAuthor.data.headshot}
        />
      <div className="grid grid-cols-blog w-full container mx-auto gap-x-4 p-2">
        <article className="col-span-2 md:col-span-1 mx-autoleading-losse my-6 xl:px-24">
          <div className="flex flex-col min-h-64 bg-white p-12 md:px-12 lg:px-24">
            <div className="hidden" data-typesense-field="type">
              Consejos
            </div>
            <span className="hidden" data-typesense-field="image" />
            <span className="hidden" data-typesense-field="article_date" />
            <span className="hidden" data-typesense-field="article_author" />
            <span
              className="hidden"
              data-typesense-field="article_author_image"
            />
            <span className="hidden" data-typesense-field="advice_source_n">
              {sourceName}
            </span>
            <span className="hidden" data-typesense-field="advice_source_d">
              {sourceDescription}
            </span>
            <span className="hidden" data-typesense-field="advice_ref_url">
              {referenceUrl}
            </span>
            <div className="flex gap-2">
              {advicesData.data.cat_group.map(item => {
                console.log({ item });
                return (
                  <Link
                    key={item.category.slug}
                    className="px-4 mb-4 shadow rounded border bg-green-300  border-green-300"
                    data-typesense-field="advice_category"
                    to={item.category.url}
                  >
                    {item.category.document.data.label.text}
                  </Link>
                );
              })}
            </div>

            <span className="hidden" data-typesense-field="recipe-type" />
            <span className="hidden" data-typesense-field="article_year" />
            <span className="hidden" data-typesense-field="tag_article" />
            <div className="hidden" data-typesense-field="advice_category" />

            <h1 data-typesense-field="title">
              {advicesData.data.title_advice.text}
            </h1>
            <div className="w-28">
              <ShareWidget
                url={pageContext.fullSlug}
                title={advicesData.data.title_advice.text}
              />
            </div>
            <div className="md:flex flex-row justify-center mt-6 -ml-6 hidden md:visible clear-both">
              <BlogTopDsktp />
            </div>
            <div className="flex flex-row justify-center mt-6 md:hidden ">
              <TopBlogMob />
            </div>
            <div className="mt-6">
              <PostOptin />
            </div>

            <div className="hidden" data-typesense-field="description">
              {advicesData.data.description_advice.text}
            </div>
            <div
              dangerouslySetInnerHTML={{
                __html: advicesData.data.description_advice.html,
              }}
            />

            <h2>
              <a
                href={referenceUrl}
                title={sourceDescription}
                target="_blank"
                rel="noreferrer"
                className="text-gray-500 tracking-wider"
              >
                <LinkIcon /> {sourceName}
              </a>
            </h2>

            {/* Botones Prev next */}
            <div className="border-t-2 flex justify-around pt-2">
              {pageContext.previous && (
                <Button
                  size="small"
                  className="rounded border-2 border-themeBrandColor border-solid bg-themeBrandColor hover:bg-white text-white hover:text-themeBrandColor py-2 px-8 focus:outline-none text-lg"
                >
                  <Link to={`/consejos/${pageContext.previous}`}>Anterior</Link>
                </Button>
              )}
              {pageContext.next && (
                <Button
                  size="small"
                  className="rounded border-2 border-themeBrandColor border-solid bg-themeBrandColor hover:bg-white text-white hover:text-themeBrandColor py-2 px-8 focus:outline-none text-lg"
                >
                  <Link to={`/consejos/${pageContext.next}`}>Siguiente</Link>
                </Button>
              )}
            </div>
          </div>
          <div className="md:flex flex-row justify-center -ml-6 hidden md:visible">
            <BlogBttmDsktp />
          </div>
          <div className="flex flex-row justify-center mt-6 md:hidden ">
            <BlogBttmMob />
          </div>
        </article>

        {/* Side Bar */}
        <aside className=" hidden md:block md:visible mx-autoleading-losse bg-white my-6">
          <Top5Recipes />
          {/* <SubscribeSide /> */}
          {/* <Top5Posts /> */}
        </aside>
      </div>
    </Layout>
  );
};

export default Advice;

export const query = graphql`
  query Advices($slug: String, $fullSlug: String) {
    advicesData: prismicAdvice(uid: { eq: $slug }) {
      data {
        description_advice {
          html
          text
        }
        title_advice {
          text
        }
        cat_group {
          category {
            slug
            url
            document {
              ... on PrismicCatego {
                id
                data {
                  label {
                    text
                  }
                }
              }
            }
          }
        }
        reference {
          raw
        }
        source {
          document {
            ... on PrismicSource {
              id
              data {
                source_name {
                  text
                }
                description {
                  text
                }
              }
            }
          }
        }
      }
    }

    pageViews(id: { eq: $fullSlug }) {
      allTimeViews
    }
  }
`;
